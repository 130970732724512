@mixin transition($transition: all 0.4s ease) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
@mixin transition-delay($transition_delay) {
  -webkit-transition-delay: $transition_delay;
  -moz-transition-delay: $transition_delay;
  -o-transition-delay: $transition_delay;
  transition-delay: $transition_delay;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}
@mixin transform-origin($transform-origin) {
  -webkit-transform-origin: $transform-origin;
  -moz-transform-origin: $transform-origin;
  -o-transform-origin: $transform-origin;
  transform-origin: $transform-origin;
}

@mixin d-flex() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin align-items-start() {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@mixin align-items() {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@mixin align-items-end() {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin justify-content-center() {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin justify-content-between() {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin justify-content-end() {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin flex-wrap($flex-wrap: wrap) {
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
}

@mixin flex-direction-column() {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-direction-row() {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
@mixin flex-direction-row-reverse() {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@mixin filter($filter: grayscale(0.5)) {
  -webkit-filter: $filter;
  filter: $filter;
}

@mixin object-fit($object-fit: cover) {
  -o-object-fit: $object-fit;
  object-fit: $object-fit;
}

@mixin object-position($object-position: center) {
  -o-object-position: $object-position;
  object-position: $object-position;
}

@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}
