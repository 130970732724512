/*
Theme Name: Farmus - Agriculture and Organic Farm HTML5 Template
Theme URI: https://themeforest.net/user/techsometimes/portfolio
Design by: techsometimes
Developed by: A N Abdullah Al Numan
Version: 1.0
License: 
Tags: 
*/
/*================================================
[  Table of contents  ]
================================================

    1 Theme Default

    2 Top Bar 

    3 Menu Bar 

    4 Banner

    5 About Us

    6 Services

    7 Projects Gallery

    8 FAQ

    9 Work Process

    10 Counting Items

    11 Our Team

    14 Massage Form

    15 Our Blogs

    16 Service Details

    17 Projects Details

    19 FAQ Info

    20 Map Info

    21 Blog Post

    22 Blog Details

    23 Info Footer

======================================
[ End table content ]
======================================*/
/*=====================
    1 Theme Default
=======================*/
/* =================
    Root Color 
=================*/
:root {
  --color-white: #ffffff;
  --color-white2: #f8f7f0;
  --color-white3: #f7f7f7;
  --color-black: #24231d;
  --color-black2: #333f4d;
  --color-gBlack: #878680;
  --color-border: #e3e3e3;
  --color-green: #4baf47;
  --color-yellow: #eec044;
  --color-tr: transparent;
}

@media (min-width: 370px) and (max-width: 576px) {
  /*=====================
      2 Top Bar 
  =======================*/
  .top-bar.v1 .top-display-info li:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (min-width: 576px) {
  /*=====================
      2 Top Bar 
  =======================*/
  .top-bar.v1 .top-display-info li:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  /*=====================
      23 Info Footer
  =======================*/
  .info-footer.v2 .row > div:nth-child(1) .footer__widget,
  .info-footer.v2 .row > div:nth-child(2) .footer__widget {
    margin-top: 0;
  }
}
@media (min-width: 767px) {
  /*=====================
      1 Theme Default
  =======================*/
  section {
    padding: 80px 0px;
  }
  /*=====================
      2 Top Bar 
  =======================*/
  .top-bar.v1 .top-display-info li:nth-child(3) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  /*=====================
      4 Banner
  =======================*/
  .banner.v1 .banner-content {
    padding: 80px 0px;
  }
  /*=====================
      5 About Us
  =======================*/
  .about-us.v1 .about-us-img {
    width: 90%;
  }
  .about-us.v1 .about-us-img img {
    width: 100%;
    margin-top: -150px;
  }
  .about-us.v1 .about-us-img .count-shap {
    width: 65%;
  }
  .about-us.v1 .about-us-img .about-profile .img-shap-left {
    width: 120px;
    height: 90px;
    left: calc(5% + 8px);
    bottom: 10%;
  }
  .about-us.v1 .about-us-img .about-profile .img-shap-right {
    width: 84px;
    height: 100px;
    top: -140px;
    right: 0;
  }
  /*=====================
      6 Services
  =======================*/
  .services.v1 .row > div:nth-last-child(2) .provide-card, .services.v2 .row > div:nth-last-child(2) .provide-card {
    margin-bottom: 0px;
  }
  /*========================
      7 Projects Gallery
  ==========================*/
  .projects-gallery.v1 .row > div:nth-last-child(2) .gallery-card, .projects-gallery.v2 .row > div:nth-last-child(2) .gallery-card {
    margin-bottom: 0px;
  }
  /*=======================
      9 Work Process
  =========================*/
  .work-process.v1 .row > div:nth-last-child(2) .work-card {
    margin-bottom: 0px;
  }
  /*=======================
      10 Counting Items
  =========================*/
  .counting-items.v1, .counting-items.v2 {
    padding-bottom: 80px;
  }
  /*========================
      11 Our Team
  ==========================*/
  .our-team.v1 .row > div:nth-last-child(2) .team-card, .our-team.v2 .row > div:nth-last-child(2) .team-card {
    margin-bottom: 0px;
  }
  /*========================
      15 Our Blogs
  ==========================*/
  .our-blogs.v1 .row > div:nth-last-child(2) .blog-card, .our-blogs.v2 .row > div:nth-last-child(2) .blog-card {
    margin-bottom: 0px;
  }
  /*=====================
      23 Info Footer
  =======================*/
  .info-footer.v2 .logo-subscriber .sent-email {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    width: calc(100% - 290px);
  }
  .info-footer.v2 .logo-subscriber .sent-email input {
    width: calc(100% - 200px);
    height: 100%;
  }
  .info-footer.v2 .logo-subscriber .sent-email input ~ button {
    margin-top: 0;
  }
  .info-footer.v2 .logo-subscriber .sent-email button {
    width: 200px;
    height: 100%;
    border-radius: 0px;
  }
}
@media (min-width: 992px) {
  /*=====================
      1 Theme Default
  =======================*/
  .section-title-center h6,
  .section-title-center-white h6 {
    max-width: 25%;
  }
  .section-title-center h2,
  .section-title-center-white h2 {
    max-width: 42%;
  }
  .section-title-center p,
  .section-title-center-white p {
    max-width: 75%;
  }
  section.pt-lg-spach {
    padding-top: 200px;
  }
  section.pb-lg-spach {
    padding-bottom: 200px;
  }
  section.mt-lg-spach {
    margin-top: -100px;
  }
  section.mb-lg-spach {
    margin-bottom: -100px;
  }
  .title-left-right .left-content {
    width: 45%;
  }
  .breadcum.v1 .breadcum-content {
    padding: 150px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: inherit;
  }
  .breadcum.v1 .breadcum-content h2 ~ ul {
    margin-top: 0px;
  }
  .play-btn.v2 {
    width: 80px;
    height: 80px;
  }
  .message-form.v1 input,
  .message-form.v1 .my-select, .message-form.v2 input,
  .message-form.v2 .my-select {
    width: calc(50% - 15px);
  }
  .message-form.v3 input,
  .message-form.v3 .my-select {
    width: calc(33.3333333% - 20px);
  }
  .blog-sidebar__wrapper .blog-sidebar__widget:first-child {
    margin-top: 0px;
  }
  .blog-sidebar__wrapper .blog-sidebar__widget-head {
    padding: 20px 30px;
    border-radius: 5px 20px 0px 0px;
  }
  .blog-sidebar__wrapper .blog-sidebar__widget-head .blog-sidebar__widget-title {
    font-size: 24px;
    line-height: 36px;
  }
  .blog-sidebar__wrapper .category-list li {
    gap: 10px;
  }
  .blog-sidebar__wrapper .category-list h6 {
    font-size: 18px;
    line-height: 28px;
  }
  /*=====================
      4 Banner
  =======================*/
  .banner.v1 .banner-content, .banner.v2 .banner-content {
    padding: 100px 50px 100px 0px;
  }
  .banner.v1 .banner-content h2, .banner.v2 .banner-content h2 {
    font-size: 65px;
    line-height: 85px;
  }
  .banner.v1 .banner-content h6 ~ h2,
  .banner.v1 .banner-content h2 ~ p, .banner.v2 .banner-content h6 ~ h2,
  .banner.v2 .banner-content h2 ~ p {
    margin-top: 8px;
  }
  .banner.v1 .banner-content p ~ ul, .banner.v2 .banner-content p ~ ul {
    margin-top: 35px;
  }
  .banner.v1 .banner-content ul, .banner.v2 .banner-content ul {
    gap: 40px;
  }
  .banner.v1::before {
    opacity: 1;
    background: linear-gradient(90deg, #24231d 0%, rgba(0, 0, 0, 0) 100%);
  }
  .banner.v1 .line-shap,
  .banner.v1 .lip-shap,
  .banner.v1 .right-bottom-shap,
  .banner.v1 .circle-shap,
  .banner.v1 .circle-dot-shap {
    display: block;
  }
  .banner.v1 .banner-content {
    padding: 100px 0px;
  }
  /*=====================
      5 About Us
  =======================*/
  .about-us.v2 {
    position: relative;
    padding: 0;
    background: var(--color-tr);
  }
  .about-us.v2 .about-us-box {
    padding: 60px 45px;
    border-radius: 5px;
    background: var(--color-white2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 45px;
  }
  .about-us.v2 .about-us-img {
    width: 50%;
  }
  .about-us.v2 .about-us-img .count-shap {
    width: 26%;
  }
  .about-us.v2 .about-us-img ~ .about-us-content {
    margin-top: 0px;
  }
  .about-us.v2 .about-us-content {
    width: 50%;
  }
  .about-us.v2 .about-us-content .box-img-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
  }
  .about-us.v2 .about-us-content .box-img-list .left-img {
    width: 50%;
  }
  .about-us.v2 .about-us-content .box-img-list .left-img ~ .check-mark-list {
    margin-top: 0px;
  }
  .about-us.v2 .about-us-content .box-img-list ul {
    width: 50%;
  }
  /*=====================
      6 Services
  =======================*/
  .services.v1 .row > div:nth-last-child(3) .provide-card, .services.v2 .row > div:nth-last-child(3) .provide-card {
    margin-bottom: 0px;
  }
  /*========================
      7 Projects Gallery
  ==========================*/
  .projects-gallery.v1 .row > div:nth-last-child(3) .gallery-card, .projects-gallery.v2 .row > div:nth-last-child(3) .gallery-card {
    margin-bottom: 0px;
  }
  /*========================
      8 FAQ
  ==========================*/
  .faq.v1 {
    position: relative;
    padding: 90px 0px 0px 0px;
    z-index: 1;
  }
  .faq.v1 .faq-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: -5vw;
    width: 63vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: -4vw 0px;
    background-size: cover;
    border-radius: 0px;
    z-index: -1;
    margin-bottom: 0px;
  }
  .faq.v1 .faq-img .small-box {
    position: relative;
    padding: 20px;
    width: 35%;
    right: calc(8vw + 23px);
    bottom: 30px;
  }
  .faq.v1 .faq-img .small-box::before {
    opacity: 0.8;
    border-radius: 5px;
  }
  .faq.v1 .faq-content {
    padding: 60px 40px;
  }
  .faq.v2 .faq-img {
    margin: 0px 0px -140px 0px;
  }
  /*=======================
      9 Work Process
  =========================*/
  .work-process.v1 .row > div:nth-last-child(3) .work-card,
  .work-process.v1 .row > div:nth-last-child(4) .work-card, .work-process.v2 .row > div:nth-last-child(3) .work-card,
  .work-process.v2 .row > div:nth-last-child(4) .work-card {
    margin-bottom: 0px;
  }
  /*=======================
      10 Counting Items
  =========================*/
  .counting-items.v1 .counting-item-list li {
    width: calc(33.3333333% - 20px);
  }
  .counting-items.v2 .counting-item-list {
    padding: 50px 100px;
  }
  .counting-items.v2 .counting-item-list li {
    width: calc(25% - 30px);
  }
  /*========================
      11 Our Team
  ==========================*/
  .our-team.v1 .row > div:nth-last-child(3) .team-card, .our-team.v2 .row > div:nth-last-child(3) .team-card, .our-team.v1 .row > div:nth-last-child(4) .team-card {
    margin-bottom: 0px;
  }
  /*========================
      15 Our Blogs
  ==========================*/
  .our-blogs.v1 .row > div:nth-last-child(3) .blog-card, .our-blogs.v2 .row > div:nth-last-child(3) .blog-card {
    margin-bottom: 0px;
  }
  /*=======================
      16 Service Details
  =========================*/
  .service-details.v1 .service-links-list h5 {
    padding: 20px 40px;
  }
  .service-details.v1 .service-links-list ul {
    padding: 4px 20px;
    border-radius: 0px 0px 5px 5px;
  }
  .service-details.v1 .service-links-list li a {
    font-size: 18px;
  }
  .service-details.v1 .service-content {
    margin-top: 0;
  }
  .service-details.v1 .serv-box-img-text .box-img,
  .service-details.v1 .serv-box-img-text .box-text {
    width: calc(50% - 15px);
  }
  .service-details.v1 .our-features ul {
    gap: 40px 30px;
  }
  .service-details.v1 .our-features ul li {
    width: calc(50% - 30px);
  }
  /*=======================
      17 Projects Details
  =========================*/
  .projects-details.v1 .projects-social {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .projects-details.v1 .main-content .projects-info .info-list {
    margin: -65px 60px 0px 60px;
  }
  .projects-details.v1 .main-content .projects-info .info-list li {
    padding-left: 40px;
    width: 25%;
  }
  .projects-details.v1 .main-content .projects-info .info-list li ~ li {
    border-left: 1px solid var(--color-border);
  }
  .projects-details.v1 .main-content .prev-next-btns {
    margin-top: 100px;
  }
  /*=======================
      18 Contact Us
  =========================*/
  .contact-us.v1 .contact-info {
    margin-top: 0;
  }
  .contact-us.v1 .contact-info .contact-list li {
    width: calc(50% - 15px);
  }
  .contact-us.v1 .contact-info .contact-list .my-icon {
    font-size: 40px;
  }
  /*=======================
      19 FAQ Info
  =========================*/
  .faq-info.v1 .accordion li {
    width: calc(50% - 15px);
  }
  /*=====================
      20 Map Info
  =======================*/
  .map-info.v1 .contact-info {
    margin-top: -70px;
  }
  .map-info.v1 .contact-info li {
    width: calc(30% - 30px);
  }
  /*=====================
      21 Blog Post
  =======================*/
  .blog-post.v1 .blog-post-card .post-body {
    padding: 50px 30px 0px 30px;
  }
  .blog-post.v1 .blog-post-card .post-body .day-box {
    position: absolute;
    right: 30px;
    top: calc(-20% - 18px);
    padding: 20px;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    color: var(--color-white);
    border-radius: 5px;
    background: var(--color-green);
    z-index: 1;
  }
  .blog-post.v1 .blog-post-card .post-body .day-box ~ .post-title {
    margin-top: 0px;
  }
  /*=====================
      22 Blog Details
  =======================*/
  .blog-details.v1 .main-text h2 {
    font-size: 48px;
    line-height: 60px;
  }
  .blog-details.v1 .main-text .article-text {
    padding: 110px 30px 35px 30px;
  }
  .blog-details.v1 .main-text .article-text::before {
    top: 35px;
    left: 30px;
    font-size: 65px;
  }
  .blog-details.v1 .next-preve-post {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .blog-details.v1 .next-preve-post a {
    width: calc(50% - 20px);
  }
  .blog-details.v1 .next-preve-post a.next-post {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-left: 1px solid var(--color-green);
  }
  /*=====================
      23 Info Footer
  =======================*/
  .info-footer.v1 .footer__widget {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  /*=====================
      1 Theme Default
  =======================*/
  section {
    padding: 100px 0px;
  }
  section.pt-xl-spach {
    padding-top: 200px;
  }
  section.pb-xl-spach {
    padding-bottom: 200px;
  }
  section.mt-xl-spach {
    margin-top: -100px;
  }
  section.mb-xl-spach {
    margin-bottom: -100px;
  }
  /*=====================
      2 Top Bar 
  =======================*/
  .top-bar.v1 {
    position: relative;
    padding-bottom: 20px;
    background: var(--color-white2);
  }
  .top-bar.v1::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 20px;
    bottom: 0;
    left: 0;
    background: var(--color-green);
  }
  .top-bar.v1 .top-info {
    padding: 20px 25px;
  }
  .top-bar.v1 .top-display-info {
    position: relative;
    gap: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 30px 25px;
    border-radius: 5px;
    background: var(--color-white);
    margin-bottom: -20px;
  }
  .top-bar.v1 .top-display-info li ~ li {
    border-left: 1px solid var(--color-border);
    padding-left: 20px;
  }
  .top-bar.v1 .top-display-info .info-icon .my-icon {
    font-size: 30px;
  }
  .top-bar.v1 .top-display-info .info-text p {
    display: block;
  }
  .top-bar.v1 .top-display-info .info-text p ~ h6 {
    margin-top: 0;
  }
  .top-bar.v1 .top-display-info .info-text h6 {
    font-size: 16px;
  }
  .top-bar.v1 .top-display-info .top-bar-search .search-close {
    top: 120px;
    right: 80px;
  }
  .top-bar.v1 .top-display-info .top-bar-search form {
    width: 50%;
    height: 70px;
  }
  .top-bar.v1 .top-display-info .top-bar-search form input {
    font-size: 24px;
  }
  .top-bar.v1 .top-display-info .top-bar-search form button {
    font-size: 24px;
  }
  /*=====================
      3 Menu Bar 
  =======================*/
  .menu-bar.v1 {
    background: var(--color-green);
  }
  .menu-bar.v1 .menu-bar-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20;
  }
  .menu-bar.v1 .mobile-header {
    display: none;
  }
  /*=====================
      4 Banner
  =======================*/
  .banner.v2 .profile-img .main-img {
    text-align: right;
  }
  .banner.v2 .profile-img .main-img::before {
    width: calc(100% - 90px);
    height: calc(100% - 50px);
    left: 40px;
    border-radius: 40% 0 0 40%;
  }
  .banner.v2 .profile-img .main-img img {
    width: calc(100% - 130px);
  }
  .banner.v2 .profile-img .plan-p {
    margin-top: 0;
  }
  .banner.v2 .profile-img .plan-p li {
    position: absolute;
    z-index: 2;
    width: 115px;
    height: 115px;
  }
  .banner.v2 .profile-img .plan-p li:nth-child(1) {
    top: calc(10% + 22px);
    left: 30px;
  }
  .banner.v2 .profile-img .plan-p li:nth-child(2) {
    top: calc(50% + 35px);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0px;
  }
  .banner.v2 .profile-img .plan-p li:nth-child(3) {
    bottom: 0;
    left: 30px;
  }
  .banner.v2 .profile-img .plan-p li:nth-child(3) ~ li {
    display: none;
  }
  /*=======================
      10 Counting Items
  =========================*/
  .counting-items.v1, .counting-items.v2 {
    padding-bottom: 0;
  }
  /*========================
      14 Massage Form
  ==========================*/
  .massage-form.v1 {
    position: relative;
    padding: 0;
    z-index: 1;
    overflow: hidden;
  }
  .massage-form.v1::before,
  .massage-form.v1 .right-bottom-shap {
    position: absolute;
    content: "";
    width: calc(50vw + 30px);
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .massage-form.v1::before {
    background: var(--color-white2);
  }
  .massage-form.v1 .main-form-massage {
    margin-top: 0;
  }
  /*=====================
      23 Info Footer
  =======================*/
  .info-footer.v1, .info-footer.v2 {
    padding-top: 85px;
  }
  .info-footer.v1 .main-footer, .info-footer.v2 .main-footer {
    margin-top: 85px;
  }
  .info-footer.v1 .footer__widget .subscriber form {
    padding: 10px 10px 10px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid var(--color-green);
    border-radius: 5px;
  }
  .info-footer.v1 .footer__widget .subscriber form input {
    width: 70%;
    border: none;
    border-radius: 0px;
  }
  .info-footer.v1 .footer__widget .subscriber form input ~ button {
    margin-top: 0;
  }
  .info-footer.v1 .footer__widget .subscriber ~ .address-info {
    margin-top: 65px;
  }
  .info-footer.v1 .footer__widget .address-info > li {
    width: calc(50% - 15px);
  }
  .info-footer.v2 .footer__widget {
    margin-top: 0;
  }
  .info-footer.v2 .logo-subscriber .footer-subscrib {
    width: calc(100% - 330px);
  }
}
@media (min-width: 1400px) {
  /*=====================
      4 Banner
  =======================*/
  .banner.v2 .profile-img .plan-p li {
    width: 135px;
    height: 135px;
  }
}